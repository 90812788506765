
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_report.ginner_wise_purchase_report') }}</h4>
            </template>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                      <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('warehouse_config.fiscal_year') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              @change="getApprovedScheduleDate"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                      <ValidationProvider name="Season" vid="seasons_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="seasons_id"
                            slot-scope="{ valid, errors }"
                        >

                          <template v-slot:label>
                            {{ $t('cotton_ginner_grower.season') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.seasons_id"
                              :options="seasonList"
                              id="seasons_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              @change="getApprovedScheduleDate"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                      <ValidationProvider name="Approved Schedule Date">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="approved_schedule_date"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('cotton_ginner_grower.approvedScheduleDate') }}
                          </template>
                          <b-form-select
                              plain
                              v-model="search.approved_schedule_date"
                              :options="dateList"
                              id="approved_schedule_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="''">
                                {{ dateLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                      <ValidationProvider name="Ginner" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="ginner_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('cotton_ginner_grower.ginner') }}
                          </template>
                          <b-form-select
                              plain
                              v-model="search.ginner_id"
                              :options="ginnerList"
                              id="ginner_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option value="0">
                                {{ $t('globalTrans.select') }}
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-button type="submit" variant="primary">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </template>
        </iq-card>
        <b-row v-show="dataShow">
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_report.ginner_wise_purchase_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new mr-2" @click="pdfExport">
                        <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                      </b-button>
                      <export-excel
                        class="btn btn_add_new"
                        :data="dataCustomizeExcel"
                        :title="$t('cotton_report.ginner_wise_purchase_report')"
                        worksheet="Report Sheet"
                        name="ginner_wise_purchase_report.xls">
                        <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                      </export-excel>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                          <b-row>
                            <b-col>
                              <list-report-head :base-url="agriMarketingServiceBaseUrl" :uri="reportHeadingList" :org-id="10">
                                {{ $t('cotton_report.ginner_wise_purchase_report') }}
                              </list-report-head>
                            </b-col>
                          </b-row>
                          <div class="text-center mb-4">
                            <table style="width:100%;color:black;">
                              <tr>
                                <td align="right" ><strong>{{ $t('elearning_config.fiscal_year') }}</strong>: {{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                <td align="center" ><strong>{{ $t('cotton_ginner_grower.season') }}</strong>: {{ ($i18n.locale==='bn') ? search.season_name_bn : search.season_name }}</td>
                                <td align="left" ><strong>{{ $t('cotton_ginner_grower.approvedScheduleDate') }}</strong>: {{ search.approved_schedule_date | dateFormat }}</td>
                              </tr>
                            </table>
                          </div>
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table-simple :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover aria-hidden="loading | listReload ? 'true' : null">
                                      <thead class="bg-primary">
                                          <tr>
                                            <th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.region') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.zone') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.unit') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.hat_name') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.ginner') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_report.purchase_date') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.cottonVariety') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.cottonName') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_report.purchase_quantity_kg') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_report.total_price') }}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <template v-if="reportList.length">
                                            <tr v-for="(item, index) in reportList" :key="index">
                                              <td>{{ $n(index + 1) }}</td>
                                              <td>{{ $i18n.locale === 'bn' ? item.region_name_bn : item.region_name }}</td>
                                              <td>{{ $i18n.locale === 'bn' ? item.zone_name_bn : item.zone_name }}</td>
                                              <td>{{ $i18n.locale === 'bn' ? item.unit_name_bn : item.unit_name }}</td>
                                              <td>{{ $i18n.locale === 'bn' ? item.hat_name_bn : item.hat_name }}</td>
                                              <td>{{ $i18n.locale === 'bn' ? item.ginner_name_bn : item.ginner_name }}</td>
                                              <td>{{ item.purchase_date | dateFormat }}</td>
                                              <td>{{ $i18n.locale === 'bn' ? item.cotton_variety_name_bn : item.cotton_variety_name }}</td>
                                              <td>{{ $i18n.locale === 'bn' ? item.cotton_name_bn : item.cotton_name }}</td>
                                              <td class="text-center align-top">{{ $n(item.purchase_quantity) }}</td>
                                              <td class="text-center align-middle">{{ $n(item.total_price) }}</td>
                                            </tr>
                                          </template>
                                          <template v-else>
                                            <tr>
                                              <td colspan="11" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                            </tr>
                                          </template>
                                      </tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, ginnerWisePurchaseReport } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { approvedScheduleDateList } from '@/modules/agri-marketing/cotton/ginner-grower/api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  data () {
    return {
      totalRows: 0,
      totalQuantity: 0,
      dataShow: false,
      search: {
        org_id: 10,
        fiscal_year_id: 0,
        seasons_id: 0,
        approved_schedule_date: '',
        ginner_id: 0
      },
      item: '',
      rows: [],
      reportList: [],
      excelData: [],
      zoneList: [],
      unitList: [],
      upazilaList: [],
      dateList: [],
      dateLoading: false,
      agriMarketingServiceBaseUrl: agriMarketingServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  mounted () {
  },
  computed: {
    loadingState () {
        if (this.listReload) {
            return true
        } else if (this.loading) {
            return true
        } else {
            return false
        }
    },
    regionList: function () {
        const regionList = this.$store.state.agriMarketing.commonObj.regionList
        return regionList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    seasonList: function () {
        const seasonList = this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
        return seasonList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    cottonVaritiesList: function () {
        const ObjectData = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.filter(item => item.status === 1)
        return ObjectData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    cottonNameList: function () {
        const ObjectData = this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => item.status === 1)
        return ObjectData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    districtList () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.sort((a, b) => b.sorting_order - a.sorting_order)
    },
    ginnerList () {
      return this.$store.state.agriMarketing.commonObj.ginnerGrowerList.filter(item => item.type === 1 && item.status === 1)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('cotton_ginner_grower.manage_schedule_entry') : this.$t('cotton_ginner_grower.manage_schedule') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.region'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.zone'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.unit_name'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.hat_name'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.ginner'), class: 'text-left' },
          { label: this.$t('cotton_report.purchase_date'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.cottonVariety'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.cottonName'), class: 'text-left' },
          { label: this.$t('cotton_report.purchase_quantity_kg'), class: 'text-left' },
          { label: this.$t('cotton_report.total_price'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'region_name_bn' },
          { key: 'zone_name_bn' },
          { key: 'unit_name_bn' },
          { key: 'hatt_name_bn' },
          { key: 'ginner_name_bn' },
          { key: 'purchase_date' },
          { key: 'cotton_variety_name_bn' },
          { key: 'cotton_name_bn' },
          { key: 'purchase_quantity' },
          { key: 'total_price' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'region_name' },
          { key: 'zone_name' },
          { key: 'unit_name' },
          { key: 'hatt_name' },
          { key: 'ginner_name' },
          { key: 'purchase_date' },
          { key: 'cotton_variety_name' },
          { key: 'cotton_name' },
          { key: 'purchase_quantity' },
          { key: 'total_price' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    dataCustomizeExcel () {
      var serial = 0
      const customizeData = this.reportList.map((item) => {
        serial += 1
        if (this.$i18n.locale === 'en') {
          return {
            'SL ': serial,
            Region: item.region_name,
            Zone: item.zone_name,
            Unit: item.unit_name,
            'Hat Name': item.hat_name,
            'Ginner Name': item.ginner_name,
            'Purchase Date': this.$options.filters.dateFormat(item.purchase_date),
            'Cotton Variety': item.cotton_variety_name,
            'Cotton Name': item.cotton_name,
            'Purchase Quanty (Kg)': item.purchase_quantity,
            'Total Price': this.$n(item.total_price)
          }
        } else {
          return {
            'ক্রমিক সংখ্যা': this.$n(serial),
            অঞ্চল: item.region_name_bn,
            জোন: item.zone_name_bn,
            ইউনিট: item.unit_name_bn,
            'হাটের নাম': item.hat_name_bn,
            'উৎপাদকের নাম': item.ginner_name_bn,
            'ক্রয় তারিখ': this.$options.filters.dateFormat(item.purchase_date),
            'সুতার বিভিন্নতা': item.cotton_variety_name_bn,
            'সুতার নাম': item.cotton_name_bn,
            'ক্রয়ের পরিমাণ (কেজি)': this.$n(item.purchase_quantity),
            'মোট দাম': this.$n(item.total_price)
          }
        }
      })
      return customizeData
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    // this.loadData()
  },
  methods: {
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    searchData () {
      this.loadData()
      this.dataShow = true
    },
    loadData () {
      const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === parseInt(this.search.fiscal_year_id))
      const seasonObj = this.$store.state.agriMarketing.commonObj.seasonList.find(item => item.value === parseInt(this.search.seasons_id))
      this.search.fiscal_year = fiscalYearObj?.text_en
      this.search.fiscal_year_bn = fiscalYearObj?.text_bn
      this.search.season_name = seasonObj?.text_en
      this.search.season_name_bn = seasonObj?.text_bn
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriMarketingServiceBaseUrl, ginnerWisePurchaseReport, this.search).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response))
        } else {
            this.reportList = []
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const cottonVaritiesList = this.$store.state.agriMarketing.commonObj.cottonVaritiesList
      const cottonNameList = this.$store.state.agriMarketing.commonObj.cottonNameList
      const seasonList = this.$store.state.agriMarketing.commonObj.seasonList
      const regionList = this.$store.state.agriMarketing.commonObj.regionList
      const zoneList = this.$store.state.agriMarketing.commonObj.zoneList
      const unitList = this.$store.state.agriMarketing.commonObj.unitList
      const hatList = this.$store.state.agriMarketing.commonObj.hatList
      const ginnerGrowerList = this.$store.state.agriMarketing.commonObj.ginnerGrowerList

        this.totalRows = data.data.length
      const listData = data.data.map(item => {
        const cottonVarityObject = cottonVaritiesList.find(data => data.value === item.cotton_variety_id)
        const cottonNameObject = cottonNameList.find(data => data.value === item.cotton_id)
        const seasonObject = seasonList.find(data => data.value === item.seasons_id)
        const regionObject = regionList.find(data => data.value === item.region_id)
        const zoneObject = zoneList.find(data => data.value === item.zone_id)
        const unitObject = unitList.find(data => data.value === item.unit_id)
        const hatObject = hatList.find(data => data.value === item.hatt_id)
        const ginnerObject = ginnerGrowerList.find(data => data.value === item.ginner_id)

        const cottonVarityData = { cotton_variety_name: cottonVarityObject?.text_en, cotton_variety_name_bn: cottonVarityObject?.text_bn }
        const cottonNameData = { cotton_name: cottonNameObject?.text_en, cotton_name_bn: cottonNameObject?.text_bn }
        const seasonData = { season_name: seasonObject?.text_en, season_name_bn: seasonObject?.text_bn }
        const regionData = { region_name: regionObject?.text_en, region_name_bn: regionObject?.text_bn }
        const zoneData = { zone_name: zoneObject?.text_en, zone_name_bn: zoneObject?.text_bn }
        const unitData = { unit_name: unitObject?.text_en, unit_name_bn: unitObject?.text_bn }
        const hatData = { hat_name: hatObject?.text_en, hat_name_bn: hatObject?.text_bn }
        const ginnerData = { ginner_name: ginnerObject?.text_en, ginner_name_bn: ginnerObject?.text_bn }

        return Object.assign({}, item, regionData, zoneData, unitData, cottonVarityData, cottonNameData, seasonData, hatData, ginnerData)
      })
      this.reportList = listData
      var serial = 0
      const rowData = this.reportList.map((item) => {
      serial += 1
      if (this.$i18n.locale === 'en') {
          return {
              'SL ': serial,
              Region: item.region_name,
              Zone: item.zone_name,
              Unit: item.unit_name,
              'Hat Name': item.hat_name,
              'Ginner Name': item.ginner_name,
              'Purchase Date': this.$options.filters.dateFormat(item.purchase_date),
              'Cotton Variety': item.cotton_variety_name,
              'Cotton Name': item.cotton_name,
              'Purchase Quanty (Kg)': item.purchase_quantity,
              'Total Price': this.$n(item.total_price)
          }
      } else {
          return {
              'ক্রমিক সংখ্যা': this.$n(serial),
              অঞ্চল: item.region_name_bn,
              জোন: item.zone_name_bn,
              ইউনিট: item.unit_name_bn,
              'হাটের নাম': item.hat_name_bn,
              'উৎপাদকের নাম': item.ginner_name_bn,
              'ক্রয় তারিখ': this.$options.filters.dateFormat(item.purchase_date),
              'সুতার বিভিন্নতা': item.cotton_variety_name_bn,
              'সুতার নাম': item.cotton_name_bn,
              'ক্রয়ের পরিমাণ (কেজি)': this.$n(item.purchase_quantity),
              'মোট দাম': this.$n(item.total_price)
          }
      }
      })
      this.excelData = rowData
    },
    getZoneList (regionId) {
        const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.region_id === regionId)
        return zoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    getUnitList (zoneId) {
        const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1 && item.zone_id === zoneId)
        return unitList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
    },
    async getApprovedScheduleDate () {
      if (this.search.fiscal_year_id && this.search.seasons_id) {
        this.dateLoading = true
        const result = await RestApi.postData(agriMarketingServiceBaseUrl, approvedScheduleDateList, this.search)
        if (result.success) {
          this.dateList = result.data
        } else {
          this.dateList = []
        }
        this.dateLoading = false
      }
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
            { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.region'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.zone'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            // { text: this.$t('org_pro_upazilla.upazilla'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.unit_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.cottonVariety'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.cottonName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.total_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      const extraData = {
        totalHeaderRows: 1
      }
      rowData.unshift(labels)
      const reportTitle = this.$t('cotton_report.ginner_wise_purchase_report')
      const columnWids = ['7%', '*', '*', '*', '*', '*', '*', '*']
      ExportPdf.exportPdf(agriMarketingServiceBaseUrl, '/cotton/config/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'MOA', false)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'region_name' : 'region_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'zone_name' : 'zone_name_bn' },
          // { key: this.$i18n.locale === 'en' ? 'upazila_name' : 'upazila_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'unit_name' : 'unit_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'cotton_variety_name' : 'cotton_variety_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'cotton_name' : 'cotton_name_bn' },
          { key: 'purchase_quantity' },
          { key: 'total_price', rowSpan: this.totalRows }
        ]
      var serial = 0
      const listData = this.reportList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'purchase_quantity') {
                // return { text: item[keyItem.key] }
                return { text: this.$n(parseFloat(item[keyItem.key])) }
            }
            if (keyItem.key === 'total_price') {
                // return { text: this.$n(parseFloat(this.totalQuantity)) }
                return { text: this.$n(parseFloat(item[keyItem.key])) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
